<template>
  <perfect-scrollbar
    :id="'scrollUserinfo'"
    class="pt-4 pb-2 px-2 ContentsBox"
    :options="scrollOption">
    <b-row class="mt-2 mx-4">
      <div class="label pl-0 mb-0 pr-1 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">氏名</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="[errors.sei ? 'errItem' : '', 'leftInput']"
          :value="userInfo.sei"
          @input="updateSei"
          maxlength="50"
          placeholder="例）山田"/>
      </div>
      <div class="pl-0">
        <b-form-input
          :disabled="!writable"
          :class="errors.mei ? 'errItem' : ''"
          :value="userInfo.mei"
          @input="updateMei"
          maxlength="50"
          placeholder="例）太郎"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.sei" :key="'sei' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.mei" :key="'mei' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">フリガナ</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="[errors.kanaSei ? 'errItem' : '', 'leftInput']"
          :value="userInfo.kanaSei"
          @input="updateKanaSei"
          maxlength="100"
          placeholder="例）ヤマダ"/>
      </div>
      <div class="pl-0">
        <b-form-input
          :disabled="!writable"
          :class="errors.kanaMei ? 'errItem' : ''"
          @input="updateKanaMei"
          :value="userInfo.kanaMei"
          maxlength="100"
          placeholder="例）タロウ"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.kanaSei" :key="'kanaSei' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.kanaMei" :key="'kanaMei' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">生年月日</label>
      </div>
      <div>
        <b-input-group class="m-0">
        <b-form-input
          :disabled="!writable"
          :value="year"
          maxlength="4"
          @input="updateYear"
          :class="[errors.barth ? 'errItem' : '', 'year']"
          placeholder="例）1980"/>
          <b-input-group-append
            class="ymdUnit flex flexMiddle">
            年
          </b-input-group-append>
        </b-input-group>
      </div>
      <div sm="2" class="pl-0">
        <b-input-group>
        <b-form-select
          :disabled="!writable"
          :value="month"
          @input="updateMonth"
          :class="[errors.barth ? 'errItem' : '', 'monthDay']"
          :options="monthOptions"
        />
          <b-input-group-append
            class="ymdUnit flex flexMiddle">
            月
          </b-input-group-append>
        </b-input-group>
      </div>
      <div sm="2" class="pl-0">
        <b-input-group>
        <b-form-select
          :disabled="!writable"
          :value="day"
          @input="updateDate"
          :class="[errors.barth ? 'errItem' : '', 'monthDay']"
          :options="dayOptions"
        />
          <b-input-group-append
            class="ymdUnit flex flexMiddle">
            日
          </b-input-group-append>
        </b-input-group>
      </div>
      <div v-if="age" class="flex flexMiddle ml-2">
        ({{age}}歳)
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.barth" :key="'barth' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>
    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="hidden mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">性別</label>
      </div>
      <div>
        <b-input-group>
          <b-form-select
            :disabled="!writable"
            :value="gender"
            @input="updateGender"
            :class="[errors.gender ? 'errItem' : '', 'gender']"
            :options="genderList"
          />
        </b-input-group>
      </div>
    </b-row>

    <b-row
      v-for="(msg, index) in errors.gender" :key="'gender' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <b-row class="mt-1">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <span class="f-13">* 選択は任意です。未選択とすることも可能です。</span>
      </div>
    </b-row>
    <hr>
    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">学位</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.degree ? 'errItem' : '', 'middle']"
          @input="updateDegree"
          :value="userInfo.degree"
          maxlength="100"
          placeholder="例）博士（工学）"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.degree" :key="'degree' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">自宅 郵便番号</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="errors.postalCode ? 'errItem' : ''"
          @input="updatePostalCode"
          :value="userInfo.postalCode"
          maxlength="7"
          placeholder="例）5410041"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.postalCode" :key="'postalCode' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">自宅 住所</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="[errors.address ? 'errItem' : '', 'long']"
          @input="updateAddress"
          :value="userInfo.address"
          maxlength="500"
          placeholder="例）大坂府大阪市中央区xx町1-2-3"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.address" :key="'address' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr class="double">

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="hidden mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">所属機関 名称</label>
      </div>
      <div class="mb-1">
        <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle SyozokuLabel">
              <span><span class="mr-2 badge badge-required">必須</span>大学、研究機関 等</span>
            </b-input-group-prepend>
          <b-form-input
            :disabled="!writable"
            :class="[errors.institutionName ? 'errItem' : '', 'middle']"
            @input="updateInstitutionName"
            :value="userInfo.institutionName"
            maxlength="100"
            placeholder="例）xxxx大学"/>
        </b-input-group>
        <div
          class="syozokuErr"
          v-for="(msg, index) in errors.institutionName" :key="'institutionName' + index">
          <div class="label pl-0 pr-1 mb-0"></div>
          <div>
            <p class="error my-1">{{msg}}</p>
          </div>
        </div>
      </div>

      <div class="ml-240px mt-2 mb-1">
        <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle SyozokuLabel">
              <span>学部、研究所、センター 等</span>
            </b-input-group-prepend>
          <b-form-input
            :disabled="!writable"
            @input="updateInstitutionName2"
            :value="userInfo.institutionName2"
            :class="[errors.institutionName2 ? 'errItem' : '', 'middle']"
            maxlength="100"
            placeholder="例）大学院工学研究科"/>
        </b-input-group>
        <div
          class="syozokuErr"
          v-for="(msg, index) in errors.institutionName2" :key="'institutionName2' + index">
          <div class="label pl-0 pr-1 mb-0"></div>
          <div>
            <p class="error my-1">{{msg}}</p>
          </div>
        </div>
      </div>

      <div class="ml-240px mt-2 mb-1">
        <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle SyozokuLabel">
              <span>学科・専攻、研究室、グループ、部門 等</span>
            </b-input-group-prepend>
          <b-form-input
            :disabled="!writable"
            @input="updateInstitutionName3"
            :value="userInfo.institutionName3"
            :class="[errors.institutionName3 ? 'errItem' : '', 'middle']"
            maxlength="100"
            placeholder="例）機械物理系専攻"/>
        </b-input-group>
        <div
          class="syozokuErr"
          v-for="(msg, index) in errors.institutionName3" :key="'institutionName3' + index">
          <div class="label pl-0 pr-1 mb-0"></div>
          <div>
            <p class="error my-1">{{msg}}</p>
          </div>
        </div>
      </div>

    </b-row>

    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">職位</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.position ? 'errItem' : '', 'middle']"
          @input="updatePosition"
          :value="userInfo.position"
          maxlength="100"
          placeholder="例）准教授"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.position" :key="'position' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">所属機関 郵便番号</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="errors.institutionPostalCode ? 'errItem' : ''"
          @input="updateInstitutionPostalCode"
          :value="userInfo.institutionPostalCode"
          maxlength="7"
          placeholder="例）5410041"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.institutionPostalCode" :key="'institutionPostalCode' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>


    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">所属機関 住所</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="[errors.institutionAddress ? 'errItem' : '', 'long']"
          @input="updateInstitutionAddress"
          :value="userInfo.institutionAddress"
          maxlength="500"
          placeholder="例）大阪府大阪市中央区xx町1-2-3"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.institutionAddress" :key="'institutionAddress' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">所属機関 電話番号</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.institutionTel ? 'errItem' : '', 'middle']"
          @input="updateInstitutionTel"
          :value="userInfo.institutionTel"
          maxlength="16"
          placeholder="例）0662223380"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.institutionTel" :key="'institutionTel' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">所属機関 FAX番号</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.institutionFax ? 'errItem' : '', 'middle']"
          @input="updateInstitutionFax"
          :value="userInfo.institutionFax"
          maxlength="16"
          placeholder="例）0662223380"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.institutionFax" :key="'institutionFax' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">所属機関 最寄駅</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.institutionStation ? 'errItem' : '', 'middle']"
          @input="updateInstitutionStation"
          :value="userInfo.institutionStation"
          maxlength="50"
          placeholder="例）JR大阪駅"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.institutionStation" :key="'institutionStation' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr class="double">

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">不在時連絡先 氏名</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.alternativeName ? 'errItem' : '', 'middle']"
          @input="updateAlternativeName"
          :value="userInfo.alternativeName"
          maxlength="100"
          placeholder="例）田中 春子"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.alternativeName" :key="'alternativeName' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row class="mt-2 mx-4">
      <div class="label p-0 mb-0 mr-10px">
        <span class="badge4line2 mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">不在時連絡先<br>電話番号</label>
      </div>
      <div sm="6">
        <b-form-input
          :disabled="!writable"
          :class="[errors.alternativeTel ? 'errItem' : '', 'middle']"
          @input="updateAlternativeTel"
          :value="userInfo.alternativeTel"
          maxlength="16"
          placeholder="例）0662223380"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.alternativeTel" :key="'alternativeTel' + index">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
    <hr>

    <b-row
      :class="[
        errors.alternativeInstitution ? '' : 'mb-3',
        'mt-2',
        'mx-4',
        ]">
      <div class="label p-0 mb-0 mr-10px">
        <span class="badge4line2 mr-2 badge badge-required">必須</span>
        <label class="mb-0 bold">不在時連絡先<br>所属機関</label>
      </div>
      <div>
        <b-form-input
          :disabled="!writable"
          :class="[errors.alternativeInstitution ? 'errItem' : '', 'long']"
          @input="updateAlternativeInstitution"
          :value="userInfo.alternativeInstitution"
          maxlength="100"
          placeholder="例）xxxx大学  大学院工学研究科"/>
      </div>
    </b-row>
    <b-row
      v-for="(msg, index) in errors.alternativeInstitution" :key="'alternativeInstitution' + index"
      class="bm-3">
      <div class="label pl-0 pr-1 mb-0"></div>
      <div class="pl-5">
        <p class="error my-1">{{msg}}</p>
      </div>
    </b-row>
  </perfect-scrollbar>
</template>
<script>
import { Core as YubinBangoCore } from 'yubinbango-core';
import moment from 'moment';
import TimeOptionHelper from '@/modules/TimeSelectBoxOption';
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'PartsApplicationUserInfo',
  props: {
    writable: { type: Boolean, default: false },
    close_datetime: { type: String, default: null },
  },
  data() {
    return {
      scrollOption: {
        wheelPropagation: true,
        suppressScrollX: true,
      },
    };
  },
  methods: {
    updateSei(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateSei', value);
    },
    updateMei(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateMei', value);
    },
    updateKanaSei(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateKanaSei', value);
    },
    updateKanaMei(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateKanaMei', value);
    },
    updatePosition(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updatePosition', value);
    },
    updateDegree(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateDegree', value);
    },
    updatePostalCode(value) {
      if (value.length > 6) {
        this._setBaseAddress(value, 'updateAddress');
      }
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updatePostalCode', value);
    },
    updateAddress(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateAddress', value);
    },
    updateInstitutionName(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionName', value);
    },
    updateInstitutionName2(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionName2', value);
    },
    updateInstitutionName3(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionName3', value);
    },
    updateInstitutionPostalCode(value) {
      if (value.length > 6) {
        this._setBaseAddress(value, 'updateInstitutionAddress');
      }
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionPostalCode', value);
    },
    updateInstitutionAddress(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionAddress', value);
    },
    updateInstitutionStation(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionStation', value);
    },
    updateInstitutionTel(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionTel', value);
    },
    updateInstitutionFax(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateInstitutionFax', value);
    },
    updateAlternativeName(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateAlternativeName', value);
    },
    updateAlternativeTel(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateAlternativeTel', value);
    },
    updateAlternativeInstitution(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateAlternativeInstitution', value);
    },
    updateYear(value) {
      const ymd = `${value}/${this.month}/${this.day}`;
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateBarth', ymd);
    },
    updateMonth(value) {
      const ymd = `${this.year}/${value}/${this.day}`;
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateBarth', ymd);
    },
    updateDate(value) {
      const ymd = `${this.year}/${this.month}/${value}`;
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateBarth', ymd);
    },
    updateGender(value) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('userInfo/updateGender', value);
    },
    _setBaseAddress(postalCode, methodName) {
      let address;
      new YubinBangoCore(postalCode, (addr) => { // eslint-disable-line no-new
        address = addr.region + addr.locality + addr.street;
        if (address) {
          this.$store.commit(`userInfo/${methodName}`, address);
        }
      });
    },
  },
  computed: {
    genderList() {
      return CONST_OPTIONS.GENDER_TYPE_OPTION;
    },
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
    barth() {
      return this.$store.state.userInfo.inputData.barth;
    },
    year() {
      if (typeof this.barth !== 'string') {
        return '';
      }
      const ymd = this.barth.split('/');
      if (ymd[0]) {
        return ymd[0];
      }
      return '';
    },
    month() {
      if (typeof this.barth !== 'string') {
        return '';
      }
      const ymd = this.barth.split('/');
      if (ymd[1]) {
        return ymd[1];
      }
      return '';
    },
    day() {
      if (typeof this.barth !== 'string') {
        return '';
      }
      const ymd = this.barth.split('/');
      if (ymd[2]) {
        return ymd[2];
      }
      return '';
    },
    errors() {
      return this.$store.state.userInfo.errors;
    },
    monthOptions() {
      return TimeOptionHelper.getMonthOptions();
    },
    dayOptions() {
      return TimeOptionHelper.getDayOptions();
    },
    age() {
      // 年にそれぞれ数値4桁がなければ年齢を表示しない、月日は入力判定のみ
      if (Number.isNaN(this.year) || this.year.length !== 4
        || !this.month
        || !this.day) {
        return null;
      }
      const birth = moment(`${this.year}-${this.month}-${this.day}`); // 誕生日
      const targetDay = moment(new Date(this.close_datetime)); // 年齢計算時点
      return targetDay.diff(birth, 'years');
    },
    gender() {
      return this.$store.state.userInfo.inputData.gender;
    },
  },
  created() {
  },
};
</script>

<style scoped>
  .errItem {
    border:solid 1px #dc3545 !important;
  }
  .error {
    font-size: 80%;
    color: #dc3545;
    /* bootstrapで太字が当てられていたので。 */
    font-weight: normal;
  }

  input {
    height: 50px;
    width: 190px;
  }

  .label {
    width: 230px;
    max-width: 230px;
  }

  .year {
    width: 120px;
  }

  .monthDay {
    height: 50px;
    width: 90px;
  }

  .middle {
    width: 400px;
  }

  .long {
    width: 750px;
  }

  .leftInput {
    margin-right: 20px;
  }

  .ymdUnit {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .badge4line2 {
    margin-bottom: 24px;
  }

  .mr-10px {
    margin-right: 10px;
  }

  .ml-240px {
    margin-left: 240px;
  }

  .SyozokuLabel {
    width: 300px;
  }

  .syozokuErr {
    margin-left: 315px;
  }

  .gender{
    width: 150px;
    height: 50px;
  }
</style>
